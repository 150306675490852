<template>
    <v-container class="pa-4 py-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-8">
            <v-icon>mdi-view-quilt</v-icon> View All IRA Coverage
        
       
            <div class="float-right row mr-2">
                <router-link :to="{name: 'CreateIRACoverage'}" tag="button" >
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2 col"   
                        >
                        <v-icon>mdi-plus</v-icon>
                        New IRA
                    </v-btn>
                </router-link>
            </div>
        </div>
        
        <v-data-table
            :headers="headers"
            :items="returnCoverage"
            class="elevation-1"
            >
           
        <template v-slot:item.actions="{ item }">
            <v-row class="ml-1">
                <router-link :to="{name: 'ViewAllIRA', params: {id: item['.key']}}">
                    <v-icon
                    small
                    >
                    mdi mdi-arrow-right-bold-circle
                    </v-icon>    
                </router-link> 
                <v-icon
                    small
                    @click="deleteItem(item)"
                    class="mr-2"
                    >
                    mdi-delete
                </v-icon>
            </v-row>
        </template>

        <template v-slot:item.CreatedAt="{ item }">
              {{$moment(item.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A')}}
          </template>

            <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="initialize"
            >
                Reset
            </v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
        dialog: false,
        deptFilter: "RISK MANAGEMENT TEAM",
        dialogDelete: false,
        headers: [
            {
            text: 'Date Covered',
            align: 'start',
            sortable: false,
            value: 'startDate',
            },
            { text: 'Created At', value: 'CreatedAt' },
            { text: 'Created By', value: 'CreatedBy' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        editedIndex: -1,
        editedItem: {},
        }),

  

        watch: {
        dialog (val) {
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
        },

        created () {
        this.initialize()
        },

        firestore(){
         return {
            Coverage: this.$db.collection('IRACoverage')
            }
        },

        computed: {
            returnCoverage(){
                let before = this.Coverage
                let self = this

                let user = self.$store.getters['useraccount/isAuthenticated']
                
                if(user.department !== 'RISK MANAGEMENT TEAM'){
                    return before.filter(a=>{
                        return a.Department == user.department
                    })          
                }

                if(self.deptFilter !== 'ALL DEPARTMENTS'){
                    return before.filter(a=>{
                        return a.Department == self.deptFilter
                    })
                }

                return before
            }
        },

        methods: {
       
        editItem (item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        async deleteItem (item) {
            let self = this

            const confirm = await this.$dialog.confirm({
                text: `Once this IRA Coverage has been deleted all IRA Reports will also be deleted and cannot be restored. Are you sure you want to continue?`,
                title: `WARNING: THIS ACTION CANNOT BE UNDONE`
            })

            if(confirm){
                 self.$db.collection('IRACoverage').doc(item['.key']).delete()
                 .then(()=>{
                  let deleteQuery = self.$db.collection('IRAReport-CC').where("IRACoverageId", "==", item['.key'])
                  deleteQuery.get().then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                      doc.ref.delete();
                    });
                  });
                 }).then(()=>{
                  let deleteQuery = self.$db.collection('IRAReport-IR').where("IRACoverageId", "==", item['.key'])
                  deleteQuery.get().then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                      doc.ref.delete();
                    });
                  });
                 })
                 .then(()=>{
                    self.$dialog.notify.success(`Deleted Item from All IRA's`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                     
                 })
            }
        },

        deleteItemConfirm () {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        save () {
                if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem)
                } else {
                this.desserts.push(this.editedItem)
                }
                this.close()
            },
        },
    }
</script>
<style scoped>
a:link {
  text-decoration: none;
}
</style>